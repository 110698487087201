// 该文件专门用于穿件整个应用的路由器
import VueRouter from 'vue-router'


export default new VueRouter({
    routes: [
        {
            path: "*",
            component: () => import('@/views/404')
        },
        {
            path: '/',
            name: 'login',
            component: () => import('@/views/login')
        }, {
            path: '/main',
            name: 'main',
            component: () => import("@/views/welcome"),
            // 重定向，自动跳转到指定路由
            children: [
                {
                    name: "首页",
                    path: "/main/test",
                    components: {main: () => import('@/views/test')}
                },{
                    name: "设备列表",
                    path: "/main/device-list",
                    components: {main: () => import('@/views/device/device-list')}
                },{
                    name: "设备出库",
                    path: "/main/device-sale",
                    components: {main: () => import('@/views/device/device-sale.vue')}
                },{
                    name: "产品管理",
                    path: "/main/product-list",
                    components: {main: () => import('@/views/product/product-list')}
                },{
                    name: "菜单列表",
                    path: "/main/menu",
                    components: {main: () => import('@/views/system/menu')}
                },{
                    name: "基础资料",
                    path: "/main/basic",
                    components: {main: () => import('@/views/system/basic')}
                },{
                    name: "权限列表",
                    path: "/main/role",
                    components: {main: () => import('@/views/system/role')}
                },{
                    name: "工单列表",
                    path: "/main/work",
                    components: {main: () => import('@/views/work/work')}
                },{
                    name: "工单类型",
                    path: "/main/work/type",
                    components: {main: () => import('@/views/work/work-type')}
                },{
                    name: "厂商列表",
                    path: "/main/factory/list",
                    components: {main: () => import('@/views/factory/factory')}
                },{
                    name: "厂商积分",
                    path: "/main/factory/points",
                    components: {main: () => import('@/views/factory/factory-points')}
                },{
                    name: "经销商列表",
                    path: "/main/sale/owner",
                    components: {main: () => import('@/views/sale-owner/sale-owner')}
                },{
                    name: "服务商列表",
                    path: "/main/service/owner",
                    components: {main: () => import('@/views/service-owner/service-owner')}
                },{
                    name: "设备分配",
                    path: "/main/device/allocation",
                    components: {main: () => import('@/views/device/device-allocation.vue')}
                },{
                    name: "触发器列表",
                    path: "/main/trigger/list",
                    components: {main: () => import('@/views/trigger/trigger-list.vue')}
                },{
                    name: "触发器类型",
                    path: "/main/trigger/type/list",
                    components: {main: () => import('@/views/trigger/trigger-type.vue')}
                },{
                    name: "数据下载",
                    path: "/main/data/download",
                    components: {main: () => import('@/views/data/download-data.vue')}
                },{
                    name: "下载列表",
                    path: "/main/data/download/list",
                    components: {main: () => import('@/views/data/download-list.vue')}
                },{
                    name: "登录日志",
                    path: "/main/system/login/log",
                    components: {main: () => import('@/views/system/login-log.vue')}
                },{
                    name: "操作日志",
                    path: "/main/system/operate/log",
                    components: {main: () => import('@/views/system/operate-log.vue')}
                },{
                    name: "设备地图",
                    path: "/main/device/map",
                    components: {main: () => import('@/views/device/device-map.vue')}
                },{
                    name: "字典管理",
                    path: "/main/system/dict",
                    components: {main: () => import('@/views/system/dict.vue')}
                },{
                    name: "缓存列表",
                    path: "/main/system/cache",
                    components: {main: () => import('@/views/system/cache.vue')}
                },{
                    name: "数据映射",
                    path: "/main/mapping/list",
                    components: {main: () => import('@/views/mapping/mapping-list.vue')}
                },
            ]
        },
    ]
})



